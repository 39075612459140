import React from 'react';
// import {
//   DesktopOutlined,
//   SettingOutlined,
//   CustomerServiceOutlined,
//   FileTextOutlined,
//   FileSyncOutlined,
//   DashboardOutlined,
//   TeamOutlined,
//   UserOutlined,
//   CreditCardOutlined,
//   BankOutlined,
// } from "@ant-design/icons";

// export const IconMenu = ({ name }) => {
//   const components = {
//     DesktopOutlined: DesktopOutlined,
//     SettingOutlined: SettingOutlined,
//     CustomerServiceOutlined: CustomerServiceOutlined,
//     FileTextOutlined: FileTextOutlined,
//     FileSyncOutlined: FileSyncOutlined,
//     DashboardOutlined: DashboardOutlined,
//     TeamOutlined: TeamOutlined,
//     UserOutlined: UserOutlined,
//     CreditCardOutlined: CreditCardOutlined,
//     BankOutlined: BankOutlined,
//     Default: DesktopOutlined,
//   };

//   const IconTag = components[name || "Default"] || SettingOutlined;
//   return <IconTag />;
// };

export const routesConfig = [
  {
    path: '/agent',
    component: 'Agent',
  },
  {
    path: '/user',
    component: 'User',
  },
  {
    path: '/coin',
    component: 'Coin',
  },
  {
    path: '/call-log',
    component: 'CallLog',
  },
  {
    path: '/purchase',
    component: 'CoinPurchase',
  },
  {
    path: '/settlement',
    component: 'AgentSettlement',
  },
  {
    path: '/addCoin',
    component: 'Settings/Settings',
  },
  {
    path: '/manageAgent',
    component: 'Settings/SettingsAgent',
  },
  {
    path: '/dashboard',
    component: 'Dashboard',
  },
  {
    path: '/',
    component: 'Dashboard',
  },
];
